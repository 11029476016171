import { css } from "@emotion/react"
import { useParams } from "react-router-dom"
import { isChurchCenterApp } from "source/Layout"
import {
  BreadcrumbDividerIcon,
  BreadcrumbNavigation,
  BreadcrumbPage,
  Error404,
} from "source/shared/components"
import { BREAKPOINTS } from "source/shared/hooks/useWindowDimensions"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { SuspenseNotes } from "../Episode/Notes"
import { useEpisode } from "source/publishing/sermons/shared/queries"
import { channelPath, episodePath } from "../shared/routes"
import { ChannelAPIRecord } from "../shared/types"
import { useQRCode } from "source/shared/QRCode"
import NativeHeader from "../shared/NativeHeader"

export function SermonNotes() {
  const { episodeId } = useParams()
  const { data: episodeJson } = useEpisode(episodeId as string)

  const channel = episodeJson.included?.find(
    (i: { type: string }) => i.type === "Channel",
  ) as ChannelAPIRecord

  if (
    episodeJson.errors ||
    !channel?.attributes?.enabled_formats?.sermon_notes
  ) {
    return <Error404 />
  }

  return <SermonNotesWithData />
}

export function SermonNotesWithData() {
  const { episodeId } = useParams()
  const { data: episodeJson } = useEpisode(episodeId as string)
  const episode = episodeJson.data
  const channel = episodeJson.included.find(
    (i: { type: string }) => i.type === "Channel",
  ) as ChannelAPIRecord

  useQRCode()

  return (
    <>
      <OpenGraphTags
        title={episode.attributes.title}
        imageUrl={episode.attributes.video_thumbnail_url}
        description={episode.attributes.description}
      />
      <NativeHeader
        title={episode.attributes.title}
        rightType={"share"}
        shareUrl={`${episode.attributes.church_center_url}/notes`}
        hideBottomDrawer={true}
      />
      {!isChurchCenterApp() && (
        <BreadcrumbNavigation>
          <BreadcrumbPage
            linkUrl={channelPath(channel)}
            pageText={channel?.attributes.name}
          />
          <BreadcrumbDividerIcon />
          <BreadcrumbPage
            linkUrl={episodePath(episode)}
            pageText={episode.attributes.title}
          />
          <BreadcrumbDividerIcon />
          <BreadcrumbPage pageText="Sermon Notes" isActive />
        </BreadcrumbNavigation>
      )}

      <section css={styles.container}>
        <SuspenseNotes notesWrapperCss={styles.notesWrapper} />
      </section>
    </>
  )
}

const styles = {
  container: css`
    max-width: min(100vw, 1200px);
    margin-inline: auto;
    padding-top: 1rem;
    padding-inline: 1.5rem;
    width: 100%;
    min-height: 100%;

    @media (max-width: 600px) {
      margin-bottom: 16px;
      padding-inline: 0;
      padding-top: 0;
    }

    @media (min-width: ${BREAKPOINTS.MD}px) {
      padding-inline: 2rem;
    }

    @media (min-width: ${BREAKPOINTS.LG}px) {
      padding: 0;
      max-width: min(100vw, 900px);
    }
  `,
  episodeHeader: css`
    padding: 1rem;
    @media screen and (min-width: 901px) {
      padding: 0;
    }
  `,
  episodeDate: css`
    color: var(--color-tint2);
    font-size: 14px;
  `,
  notesWrapper: css`
    @media screen and (min-width: ${BREAKPOINTS.SM}px) {
      border: 1px solid var(--color-tint6);
      border-radius: 4px;
      flex: 1;
    }
  `,
}
